import { Accordion, Col, Row } from "react-bootstrap";

// probabilmente costruire una interfaccia per le props
const SentenceAccordion = (props: any) => {
    return (
        <>
            <Accordion className="py-2" defaultActiveKey={`${!props.open ? '' : '0'}`}>
                <Accordion.Item className="accordion-item" eventKey="0">
                    <Accordion.Header className="accordion-header">
                        <small>Sentenza</small>
                    </Accordion.Header>
                    <Accordion.Body>
                    {props.showWarning && <div id="alert-warning" className="alert alert-warning" role="alert">{props.warningContent}</div>}
                    {props.showInfo && <div id="alert-info" className="alert alert-info" role="alert">{props.infoContent}</div>}
                        <Row className="my-0">
                            <Col className="width-all col-md-6">
                                <div className='px-1 py-1 align-items-center justify-content-center viewer-container-110'>
                                    {
                                        props.ready ?
                                            <iframe title='Documento pdf della sentenza' src={props.urlFile} height="100%" width="100%"></iframe>
                                            : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default SentenceAccordion;