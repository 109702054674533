import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchSearchDetails } from "../store/searchDetails/api";
import { useAppDispatch, useAppSelector } from "../store/store";
import { CONSTANTS } from "../utils/constants";
import { clearRequest, setReturnFromDetails } from "../store/search/reducer";
import Error from "../components/Error";
import '../components/styles.css';
import Spinner from "../components/Spinner";
import SentenceAccordion from "../components/SentenceAccordion";

const SearchDetails = () => {

    type SentenzaType = {
        "annoSentenza": number,
        "codiceCommissione": string,
        "gradoCommissione": number,
        "idFilenet": string,
        "nrSentenza": number,
        "regioneCommissione": string,
        "sentenzaSegnalata": number
    }

    type ResponseDetailType = {
        "dataDeposito": string,
        "esito": string,
        "esitoDescr": string,
        "macroEsito": string,
        "fgAppello": number,
        "gradoCommissione": number,
        "materia": string,
        "materiaDesc": Array<string>,
        "sentenze": null | Array<SentenzaType>,
        "speseGiudizio": number,
        "speseGiudizioDesc": number,
        "speseGiudizioParte": number,
        "speseGiudizioUfficio": number,
        "tipoGiudizio": number,
        "tipoGiudizioDesc": number,
        "valoreControversia": number,
        "numeroSentenza": number,
        "annoSentenza": number,
        "regioneCommissione": string
        "cittaCommissione": string
    }

    const { data, loading, request, paginationDetails } = useAppSelector((state) => state.searchResults);
    const { response } = useAppSelector((state) => state.searchResponse);
    const searchDetailsDispatch = useAppDispatch();
    const [urlFile, setUrlFile] = useState("");
    const [ready, setReady] = useState(false);
    const [flgTedesco, setFlgtedesco] = useState(false);
    const [responseDetail, setResponseDetail] = useState<ResponseDetailType>();
    const [idFilenet, setIdFilenet] = useState("")
    const [errorFromBE, setErrorFromBE] = useState(false)
    const [filterMateriaClass, setFilterMateriaClass] = useState<Array<{
        codiceClassificazione: ""
        codiceMateria: ""
        descrizioneMateria: ""
        descrizioneClassificazione: ""
    }>>([])

    const infoContent = "Sentenza in lingua tedesca"

    const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Dettaglio sentenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
        try {
            if (window._paq) {
                window._paq.push(['setCustomUrl', String(window.location)]);
                window._paq.push(['setDocumentTitle', window.document.title]);
                window._paq.push(['setGenerationTimeMs', 0]);
                window._paq.push(['trackPageView']);
            }
        } catch (e) {
            console.error('trackPageView error', e);
        }
        setTimeout(() => {
            window.scrollTo({
                top: 0,
            });
        }, 100);
        fetch(`${apiPath}/v1/filter/materia/classificazione/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then((response) => {
                setFilterMateriaClass(response)
            })
        if (location?.state?.idFilenet) {
            setIdFilenet(location?.state?.idFilenet)
            // loadingPage()
        }
    }, []);

    useEffect(() => {
        if (idFilenet !== "") {
            setResponseDetail(undefined)
            setReady(false)
            loadingPage()
        }
    }, [idFilenet])

    const loadingPage = () => {
        searchDetailsDispatch(fetchSearchDetails)
        showPdf()
        searchDetailsDispatch(setReturnFromDetails(false))
        setTimeout(() => {
            const startPage = document.getElementById('search-detail')
            startPage?.scrollIntoView()
        }, 100);

        fetch(`${apiPath}/v1/search/detail/${idFilenet}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then((result) => {
                if (result.flgTedesco && result.flgTedesco === 1) {
                    setFlgtedesco(true)
                }
                if (result.statusCode && result.statusCode === 500) {
                    setErrorFromBE(true)
                }
                else {
                    setErrorFromBE(false)
                    setResponseDetail(result)
                }
            })
            .catch((res) => setErrorFromBE(true))
    }

    const showPdf = () => {
        const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC
        fetch(`${apiPath}/v1/search/content/${idFilenet}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then((response) => {
                setUrlFile("data:application/pdf;base64," + response.content + "#view=FitH");
                setReady(true)
            })
            .catch(() => setReady(false))
    }

    const base64ToArrayBuffer = (base64: string) => {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    const createAndDownloadBlobFile = (body: any, filename: string, extension = 'pdf') => {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const downloadPdf = (e: any) => {
        if (window._paq) {
            window._paq.push(['trackLink', String(window.location)+'/download', 'download']);
        }
        
        const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC
        fetch(`${apiPath}/v1/search/content/${idFilenet}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then((response) => {
                const arrayBuffer = base64ToArrayBuffer(response.content);
                createAndDownloadBlobFile(arrayBuffer, response.nomeFile !== "" ? response.nomeFile : "noNameFile");
            })
    }

    const segnalaSentenzaCallback = () => {
        const jsonState = {
            autoritaEmittente: `CGT ${responseDetail?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} ${responseDetail?.gradoCommissione === 1 ? responseDetail?.cittaCommissione : responseDetail?.regioneCommissione ? responseDetail?.regioneCommissione : ""}`,
            numero: responseDetail?.numeroSentenza,
            anno: responseDetail?.annoSentenza,
            idFilenet
        }
                                      
        localStorage.setItem("state", JSON.stringify(jsonState));
        //localStorage.setItem("request", JSON.stringify(request));
        //localStorage.setItem("response", JSON.stringify(response));
        localStorage.setItem("flgTedesco", JSON.stringify(flgTedesco));
        //localStorage.setItem("paginationDetails", JSON.stringify(paginationDetails));
        navigate('/ricerca/dettaglio/segnala-sentenza', { state: jsonState })
    }

    const buildMateria = (materia: Array<string>) => {
        if (!materia) return null;
        return (
            materia?.length === 0 ?
                <label className="color-black">
                    -
                </label>
                :
                materia?.length > 1 ?
                    <ul>
                        {materia?.map((val: string, id: number) => {
                            return <li key={id} className="label-matter">{val}</li>
                        })
                        }
                    </ul>
                    :
                    <label className="label-esito">
                        {materia[0]}
                    </label>
        )
    }

    const clearRequestParams = () => {
        searchDetailsDispatch(clearRequest(""))
        localStorage.removeItem('request')
        localStorage.removeItem('response')
        localStorage.removeItem('paginationDetails')
    }

    return (
        <div role='region' aria-label='Dettaglio sentenza' id="search-detail" className="SearchDetails">
            {
                !errorFromBE ?
                    idFilenet !== "" ?
                        responseDetail ?
                            <div className="container py-3">
                                <Row className="my-3">
                                    <Col className="col-md-0">
                                        <nav aria-label="Ti trovi in">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to='/' onClick={clearRequestParams}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathHomeLinkLabel}</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to='/ricerca' onClick={clearRequestParams}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathLinkLabel}</Link>
                                                </li>
                                                <li className="color-black breadcrumb-item" aria-current="page">
                                                    <Link to='/ricerca' onClick={() => searchDetailsDispatch(setReturnFromDetails(true))}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathListLinkLabel}</Link>
                                                </li>
                                                <li className="color-black breadcrumb-item active" aria-current="page">{`Dettaglio sentenza`}</li>
                                            </ol>
                                        </nav>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col className="col-md-0">
                                        <h2 className="color-black">
                                            {`CGT ${responseDetail?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} ${responseDetail?.gradoCommissione === 1 ? responseDetail?.cittaCommissione : responseDetail?.regioneCommissione ? responseDetail?.regioneCommissione : ""}`}
                                        </h2>
                                        <h2 className="color-black">
                                            {responseDetail?.numeroSentenza && responseDetail?.annoSentenza ?
                                                `Sentenza n. ${responseDetail?.numeroSentenza}/${responseDetail?.annoSentenza}`
                                                : responseDetail?.numeroSentenza ? `Sentenza n. ${responseDetail?.numeroSentenza}`
                                                    : ""}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="my-4">
                                    <Col className="col-md-5">
                                        <Button
                                            variant="outline-primary me-3"
                                            onClick={downloadPdf}
                                            title="Scarica il pdf della sentenza"
                                            className="mt-2"
                                        >
                                            <i className="bi bi-download my-2 mx-2"></i>
                                            <span>Scarica sentenza</span>
                                        </Button>
                                        <Button
                                            variant="outline-primary"
                                            onClick={segnalaSentenzaCallback}
                                            title="Accedi al servizio autenticato di segnalazione anomalia sull'anonimizzazione della sentenza"
                                            className="mt-2"
                                        >
                                            <i className="bi bi-exclamation-triangle my-2 mx-2"></i>
                                            <span>Invia segnalazione</span>
                                        </Button>
                                    </Col>

                                </Row>
                                <Accordion className="py-2">
                                    <Accordion.Item className="accordion-item" eventKey="0">
                                        <Accordion.Header className="accordion-header">
                                            <small>Dati di riepilogo</small>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Col className="my-2 mx-4 col-auto">
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Data di deposito:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <label className="color-black">
                                                            {responseDetail?.dataDeposito}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Valore controversia:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <label className="color-black">
                                                            {responseDetail?.valoreControversia ? `${(parseFloat(responseDetail?.valoreControversia.toString()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} €` : null}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Tipo giudizio:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <label className="color-black">
                                                            {responseDetail?.tipoGiudizioDesc}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Esito:
                                                        </label>
                                                    </Col>
                                                    <Col className="col-4">
                                                        {/* {responseDetail?.esito === responseDetail?.macroEsito ?
                                                    <label className="label-esito">
                                                        {responseDetail?.esito}
                                                    </label>
                                                    :
                                                    <div>
                                                        <label className="label-esito">
                                                            {responseDetail?.macroEsito}
                                                        </label>
                                                        <label className="label-esito">
                                                            {`(${responseDetail?.esito})`}
                                                        </label>
                                                    </div>
                                                } */}
                                                        <label className="color-black">
                                                            {responseDetail?.esitoDescr}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Materia:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            {
                                                                buildMateria(responseDetail?.materiaDesc)
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Spese giudizio:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <label className="label-esito">
                                                            {responseDetail?.speseGiudizioDesc ? responseDetail?.speseGiudizioDesc : '-'}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Importo a carico del contribuente:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <label className="label-esito">
                                                                {responseDetail?.speseGiudizioParte ? `${(parseFloat(responseDetail?.speseGiudizioParte.toString()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} €` : '-'}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="my-4">
                                                    <Col className="col-sm-4 col-md-3">
                                                        <label className="form-label color-black">
                                                            Importo a carico dell'ufficio:
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <label className="label-esito">
                                                                {responseDetail?.speseGiudizioUfficio ? `${(parseFloat(responseDetail?.speseGiudizioUfficio.toString()).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} €` : '-'}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {responseDetail.gradoCommissione === 1 ?
                                                    <Row className="my-4">
                                                        <Col className="col-sm-4 col-md-3">
                                                            <label className="form-label color-black">
                                                                Presenza appello:
                                                            </label>
                                                        </Col>
                                                        <Col>
                                                            <div>
                                                                {
                                                                    responseDetail?.fgAppello === 0 ?
                                                                        <label className="label-esito">
                                                                            Non risulta proposto appello
                                                                        </label>
                                                                        :
                                                                        responseDetail?.fgAppello === 1 ?
                                                                            <label className="label-esito">
                                                                                Risulta proposto appello non ancora definito con sentenza
                                                                            </label>
                                                                            : responseDetail?.sentenze ?
                                                                                responseDetail.sentenze.length === 1 ?
                                                                                    <label className="label-esito">
                                                                                        {responseDetail.sentenze[0].idFilenet && responseDetail.sentenze[0].sentenzaSegnalata == 0 ?
                                                                                            <span>Appello definito con <Link to='/ricerca/dettaglio' onClick={() => setIdFilenet(responseDetail.sentenze ? responseDetail.sentenze[0].idFilenet : "")}>sentenza n. {responseDetail.sentenze[0].nrSentenza}/{responseDetail.sentenze[0].annoSentenza} CGT {responseDetail?.sentenze[0]?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} {responseDetail.sentenze[0].regioneCommissione} {responseDetail?.sentenze[0]?.gradoCommissione === 1 ? responseDetail.sentenze[0].codiceCommissione : ""}</Link></span>
                                                                                            : (responseDetail.sentenze[0].sentenzaSegnalata == 1 ?
                                                                                            <span>Appello definito con sentenza n. {responseDetail.sentenze[0].nrSentenza}/{responseDetail.sentenze[0].annoSentenza} CGT {responseDetail?.sentenze[0]?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} {responseDetail.sentenze[0].regioneCommissione} {responseDetail?.sentenze[0]?.gradoCommissione === 1 ? responseDetail.sentenze[0].codiceCommissione : ""}</span>
                                                                                            : `Appello presente`)
                                                                                        }
                                                                                    </label>
                                                                                    :
                                                                                    <>
                                                                                        <label className="label-esito">
                                                                                            Sono presenti più sentenze di secondo grado.
                                                                                        </label>
                                                                                        {
                                                                                            <ul>
                                                                                                {
                                                                                                    responseDetail?.sentenze.map((val, id) =>
                                                                                                        val.idFilenet && val.sentenzaSegnalata == 0 ?
                                                                                                            <li key={id}>Appello definito con <Link to='/ricerca/dettaglio' onClick={() => setIdFilenet(val.idFilenet)}>sentenza n. {val.nrSentenza}/{val.annoSentenza} CGT {val?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} {val.regioneCommissione} {val.gradoCommissione === 1 ? val.codiceCommissione : ""}</Link></li>
                                                                                                            : (val.sentenzaSegnalata == 1 ?
                                                                                                            <li key={id}>Appello definito con sentenza n. {val.nrSentenza}/{val.annoSentenza} CGT {val?.gradoCommissione === 1 ? "primo grado" : "secondo grado"} {val.regioneCommissione} {val.gradoCommissione === 1 ? val.codiceCommissione : ""}</li>
                                                                                                            : <li key={id}>Appello presente</li>)
                                                                                                    )
                                                                                                }
                                                                                            </ul>
                                                                                        }
                                                                                    </>
                                                                                : '-'

                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row> : null}

                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <SentenceAccordion urlFile={urlFile} ready={ready} showInfo={flgTedesco} infoContent={infoContent} open={true} />
                            </div>
                            : <Spinner></Spinner>
                        : <Error message="la pagina richiesta non esiste!" styleContainer={true}></Error>
                    : <Error message="si è verificato un errore!" styleContainer={true}></Error>
            }
        </div>
    );
}

export default SearchDetails;