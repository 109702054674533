import React, { FormEvent, useEffect, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { clearSegnalazione, setEmail, setTesto, setCode } from "../store/segnalaSentenza/reducer";
import { useAppDispatch, useAppSelector } from "../store/store";
import { CONSTANTS, EMAIL_REGEX, TEXTAREA_WHITELIST_REGEX } from "../utils/constants";
import './search.css'
import { Link } from "react-router-dom";
import SearchItem from "../components/SearchItem";
import { clearRequest, setPaginationDetails, setRequestAfterRefresh, setReturnFromDetails } from "../store/search/reducer";
import '../components/styles.css';
import Error from "../components/Error";
import '../components/styles.css';
import SentenceAccordion from "../components/SentenceAccordion";
import Spinner from "../components/Spinner";
import Modal from "../components/Modal";
import { setResults } from "../store/searchResults/reducer";
import { callbackOnDelay } from "../utils/functions";

const SegnalaSentenzaPage = () => {

    const apiPathPrivate = process.env.REACT_APP_SERVER_HOST_PRIVATE
    const [isErrorTesto, setIsErrorTesto] = useState(false)
    const [isErrorEmail, setIsErrorEmail] = useState(false)
    const [isErrorCode, setIsErrorCode] = useState(false)
    const { requestSegnalazione } = useAppSelector((state) => state.segnalaSentenza);
    const dispatch = useAppDispatch();
    const searchDetailsDispatch = useAppDispatch();
    const [urlFile, setUrlFile] = useState("")
    const [ready, setReady] = useState(false)
    const [errorMessageEmail, setErrorMessageEmail] = useState("")
    const [errorMessageCode, setErrorMessageCode] = useState("")
    const [errorMessageUserDisable, setErrorMessageUserDisable] = useState("La sentenza non può essere segnalata")
    const [disableCheckMail, setDisableCheckMail] = useState(true)
    const [disableCheckCode, setDisableCheckCode] = useState(true)
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [showMailInfo, setShowMailInfo] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [codeIsValid, setCodeIsValid] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showFailed, setShowFailed] = useState(false)
    const [submitFailedMessage, setSubmitFailedMessage] = useState("Siamo spiacenti! Si è verificato un errore durante l'invio della segnalazione.")
    const [disableAllFields, setDisableAllFields] = useState(false)
    const [showEmailError, setShowEmailError] = useState(false)
    const [emailError, setEmailError] = useState("Siamo spiacenti! Si è verificato un errore durante la verifica della mail.")
    const [enable, setEnable] = useState(true)
    const [regexFailed, setRegexFailed] = useState(false)
    const [metadata, setMetadata] = useState<{
        codiceCommissione: string,
        gradoCommissione: number,
        idPgd: number
    }>()
    const [serverError, setServerError] = useState(false)
    const [showPrivacyModal, setShowPrivacyModal] = useState(false)
    const [checkPrivacy, setCheckPrivacy] = useState(false)
    const state = JSON.parse(localStorage.getItem('state') as string)
    const stateFlgTedesco = localStorage.getItem('flgTedesco') as string
    const requestSearchDetails = JSON.parse(localStorage.getItem('request') as string)
    const responseSearch = JSON.parse(localStorage.getItem('response') as string)
    const paginationDetails = JSON.parse(localStorage.getItem('paginationDetails') as string)

    const sendTryErrorMessage = `Errore durante l'invio della segnalazione, si prega di riprovare. Consulta le Info e Assistenza per ulteriori informazioni.`;
    const substring = "2°";

    const sentenceTitle = `Segnala sentenza n. ${state?.numero}/${state?.anno} ${state?.autoritaEmittente.includes(substring) ? state.autoritaEmittente.replace("2°", "secondo grado") : state.autoritaEmittente.replace("1°", "primo grado")}`;
    const sentenceError = `Non è possibile inviare una segnalazione per questa sentenza. 
    Consulta le Info e Assistenza per ulteriori informazioni.`

    //const flgWarning = `Attenzione! Il contenuto della sentenza è in lingua tedesca`
    const infoContent = `Sentenza in lingua tedesca`

    const handle401Error = (response : Response) => {
        if (response.status === 401) {
            window.location.replace("/login"); // Modifica questo per l'URL appropriato della pagina di login
            return;
        }
        return response;
    };

    const privacyModalTitle = `Informativa sul trattamento dei dati personali (Informativa Privacy) ai sensi dell'articolo 13 del Regolamento (UE) 2016/679 (GDPR) - "Segnalazione anomalie sentenze tributarie"`
    const privacyModalContent = `<section>
    <p><b>Premessa</b></p>
    <p>Il Ministero dell'economia e delle finanze, in qualità di Titolare del trattamento, informa, ai sensi dell'art. 13 del Regolamento (UE) 2016/679 (GDPR), che i dati personali raccolti nell'ambito delle segnalazioni da parte degli utenti (a mezzo SPID) per eventuali anomalie nel mascheramento dei dati personali presenti nelle sentenze della banca dati della giurisprudenza tributaria di merito saranno trattati come segue.</p>
    <p><b>Titolare del trattamento</b></p>
    <p>Il Titolare del trattamento dei dati personali è il Ministero dell'economia e delle finanze, nelle articolazioni previste dall'articolo 25, comma 1, del D.lgs. n. 300/1999, e dal D.P.C.M. n. 103/2019 e successive modifiche e integrazioni, con sede in Via XX Settembre, 97 - 00187 Roma.</p>
    <p>Il Titolare può essere contattato tramite PEC all'indirizzo: <a href="mailto:dgt.direzionegenerale@pec.mef.gov.it">dgt.direzionegenerale@pec.mef.gov.it</a></p>
    <p><b>Responsabile del trattamento dei dati</b></p>
    <p>Il Titolare si avvale di Sogei Spa in qualità di partner tecnologico al quale è affidata la gestione del sistema informativo della giustizia tributaria e della banca dati della giurisprudenza tributaria di merito.</p>
    <p><b>Responsabile della protezione dei dati</b></p>
    <p>Il Responsabile della protezione dei dati (o Data Protection Officer) è designato dal Titolare per assolvere alle funzioni espressamente previste dal Regolamento (UE) 2016/679 (GDPR).</p>
    <p>Il Responsabile della protezione dei dati del Ministero dell'economia e delle finanze può essere contattato:</p>
    <ul>
        <li>tramite posta elettronica ordinaria all'indirizzo: <a href="mailto:responsabileprotezionedati@mef.gov.it">responsabileprotezionedati@mef.gov.it</a></li>
        <li>tramite PEC all'indirizzo: <a href="mailto:rpd@pec.mef.gov.it">rpd@pec.mef.gov.it</a></li>
    </ul>
    <p><b>Dati personali trattati</b></p>
    <p>Sono trattate le seguenti categorie di dati personali:</p>
    <ul>
        <li>Dati personali (articolo 4, numero 1, del Regolamento);</li>
    </ul>
    <p>In particolare, sono trattati i seguenti dati personali:
    <ul>
        <li>Nome</li>
        <li>Cognome</li>
        <li>Codice fiscale</li>
        <li>Indirizzo email</li>
    </ul>
    <p>Inoltre, possono essere trattati i dati personali eventualmente forniti attraverso la compilazione del form di segnalazione di un'anomalia nel mascheramento dei dati personali contenuti nelle sentenze.</p>
    <p><b>Finalità del trattamento, condizioni di liceità e base giuridica</b></p>
    <p>I dati personali sono trattati per la segnalazione di anomalie relative al mascheramento dei dati personali presenti nelle sentenze della banca dati della giurisprudenza tributaria di merito.</p>
    <p>La condizione di liceità del trattamento dei dati personali è:</p>
    <ul>
        <li>esecuzione di un compito d'interesse pubblico o connesso all'esercizio di pubblici poteri - art. 6, par. 1, lett. e) del Regolamento (UE) 2016/679 (GDPR).</li>
    </ul>
    <p><b>Modalità di trattamento dei dati personali</b></p>
    <p>Il trattamento dei dati personali è effettuato, per le finalità sopra descritte, in conformità a quanto stabilito dall'art. 5 del Regolamento (UE) 2016/679 (GDPR) e dal D.lgs. n. 196/2003, nel rispetto delle regole di riservatezza e di sicurezza previste dalla normativa vigente, in maniera tale da impedire l'accesso o l'utilizzo non autorizzato dei dati personali.</p>
    <p>I dati personali sono trattati e conservati nel rispetto dei principi di necessità, liceità, correttezza, trasparenza, esattezza, minimizzazione dei dati e limitazione del periodo di conservazione, mediante l'adozione di misure tecniche e organizzative adeguate al livello di rischio dei trattamenti posti in essere.</p>
    <p><b>Comunicazione dei dati a eventuali destinatari</b></p>
    <p>I dati personali potranno essere resi accessibili o comunicati, nel rispetto della normativa vigente e per il perseguimento delle finalità sopra descritte a:</p>
    <ul>
        <li>Sogei Spa, incaricata dal Titolare di svolgere servizi e attività contrattuali di varia natura, nominata Responsabile del trattamento ai sensi dell'art. 28 del Regolamento (UE) 2016/679 (GDPR);</li>
        <li>Altri responsabili del trattamento ai sensi dell'art. 28 del Regolamento (UE) 2016/679 (GDPR), tra quelli che presentano garanzie idonee per mettere in atto misure tecniche e organizzative adeguate in modo tale che il trattamento soddisfi i requisiti del Regolamento (UE) 2016/679 (GDPR) e garantiscano la tutela dei diritti degli interessati.</li>
    </ul>
    <p>I dati personali non saranno oggetto di diffusione a soggetti indeterminati, in una forma che consenta l'identificazione degli interessati, anche mediante la loro messa a disposizione o consultazione. Tuttavia, se necessario, i dati personali potranno essere comunicati:</p>
    <ul>
        <li>ai soggetti cui la comunicazione debba essere effettuata in adempimento di un obbligo previsto dalla legge, da un regolamento o dalla normativa UE, ovvero per adempiere ad un ordine dell'autorità giudiziaria;</li>
        <li>ad altri eventuali soggetti terzi, qualora la comunicazione si renda necessaria per la tutela del Ministero dell'economia e delle finanze in sede giudiziaria, nel rispetto delle vigenti disposizioni in materia di protezione dei dati personali.</li>
    </ul>
    <p><b>Trasferimento dei dati personali verso paesi terzi o organizzazioni internazionali</b></p>
    <p>Il Titolare non trasferisce i dati personali verso paesi terzi (al di fuori della UE o dello Spazio Economico Europeo) o organizzazioni internazionali.</p>
    <p><b>Conservazione dei dati</b></p>
    <p>I dati personali sono conservati per il tempo strettamente necessario all'evasione della segnalazione e cancellati entro 30 giorni dalla sua risoluzione.</p>
    <p>I dati personali possono essere conservati per periodi più lunghi esclusivamente a fini di archiviazione nel pubblico interesse, di ricerca scientifica o storica o a fini statistici, ai sensi dell'art. 5, par. 1, lett. e), e dell'art. 89, del Regolamento (UE) 2016/679 (GDPR).</p>
    <p><b>Profilazione</b></p>
    <p>I dati personali non sono oggetto di profilazione o di processi decisionali automatizzati.</p>
    <p><b>Diritti dell'interessato</b></p>
    <p>L'interessato ha il diritto di ottenere dal Titolare la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e, in tal caso, di ottenere l'accesso a tali dati personali e alle seguenti informazioni: le finalità del trattamento, le categorie di dati personali, i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati (in particolare se destinatari di paesi terzi o organizzazioni internazionali), il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo, tutte le informazioni disponibili sull'origine dei dati personali, qualora non siano raccolti presso l'interessato, l'esistenza di un processo decisionale automatizzato, compresa la profilazione, e informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato.</p>
    <p>Nei casi e nei limiti previsti dal Regolamento (UE) 2016/679 (GDPR), l'interessato ha altresì il diritto di:</p>
    <ul>
        <li>revocare gli eventuali consensi al trattamento dei dati personali in qualsiasi momento, senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca stessa;</li>
        <li>ottenere la rettifica dei dati personali inesatti che lo riguardano;</li>
        <li>ottenere l'integrazione dei dati personali incompleti che lo riguardano, tenuto conto delle finalità del trattamento, anche fornendo una dichiarazione integrativa;</li>
        <li>ottenere la cancellazione ("diritto all'oblio") dei dati personali che lo riguardano;</li>
        <li>ottenere la limitazione del trattamento dei dati personali che lo riguardano (in tal caso, i dati personali sono trattati, salvo che per la conservazione, soltanto con il consenso dell'interessato o per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria oppure per tutelare i diritti di un'altra persona fisica o giuridica o per motivi di interesse pubblico rilevante dell'UE o di uno Stato membro);</li>
        <li>ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico, i dati personali che lo riguardano ("diritto alla portabilità dei dati");</li>
        <li>trasmettere i dati personali che lo riguardano a un altro Titolare del trattamento, senza impedimenti da parte del Titolare del trattamento cui li ha forniti, qualora tecnicamente fattibile ("diritto alla portabilità dei dati");</li>
        <li>opporsi al trattamento dei dati personali che lo riguardano.</li>
    </ul>
    <p>Per esercitare i sopra elencati diritti, l'interessato può contattare il Titolare tramite PEC all'indirizzo: <a href="mailto:dgt.direzionegenerale@pec.mef.gov.it">dgt.direzionegenerale@pec.mef.gov.it</a></p>
    <p>Qualora l'interessato ritenga che il trattamento effettuato dal Titolare possa aver violato le norme vigenti in materia di protezione dei dati personali, ha il diritto di proporre reclamo all'Autorità Garante per la protezione dei dati personali, ai sensi dell'art. 77 del Regolamento (UE) 2016/679 (GDPR), mediante:</p>
    <ul>
        <li>raccomandata A/R indirizzata a "Garante per la Protezione dei Dati Personali", Piazza Venezia 11, 00187 Roma</li>
        <li>posta elettronica ordinaria all'indirizzo: <a href="mailto:protocollo@gpdp.it">protocollo@gpdp.it</a></li>
        <li>PEC all'indirizzo: <a href="mailto:protocollo@pec.gpdp.it">protocollo@pec.gpdp.it</a></li>
    </ul>
  </section>`

    useEffect(() => {
        document.title = "Segnala sentenza - " + CONSTANTS.ROOT_PAGE.headerTitle;
        try {
            if (window._paq) {
              window._paq.push(['setCustomUrl', String(window.location)]);
              window._paq.push(['setDocumentTitle', window.document.title]);
              window._paq.push(['setGenerationTimeMs', 0]);
              window._paq.push(['trackPageView']);
            }
        } catch (e) {
        console.error('trackPageView error', e);
        }
    }, []);


    useEffect(() => {
        //UTILIZZO PROPRIETA' REFRESH PER FORZARE LA REDIRECT ALLA LOGIN
        let refresh = localStorage.getItem('refresh')
        if (!refresh) {
            localStorage.setItem('refresh', 'false')
        }
        //SETTO I PARAMETRI DI RICERCA NELLO STORE REDUX DOPO IL REFRESH
        searchDetailsDispatch(setRequestAfterRefresh(requestSearchDetails))
        //SETTO I RISULTATI DI RICERCA NELLO STORE REDUX DOPO IL REFRESH
        searchDetailsDispatch(setResults(responseSearch))
        //SETTO I DATI DELLA PAGINAZIONE NELLO STORE REDUX DOPO IL REFRESH
        searchDetailsDispatch(setPaginationDetails(paginationDetails))
        
        //RECUPERO IDPGD DAI METADATA
        fetch(`${apiPathPrivate}/v1/segnalazione/metadata/${state?.idFilenet}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(handle401Error)
            .then(res => {
                if (res) {
                    return res.json();
                } 
                return undefined;
            })
            .then((response) => {
                setMetadata(response)
                //VERIFICO SE LA SENTENZA PUO ESSERE SEGNALATA
                fetch(`${apiPathPrivate}/v1/segnalazione/isenable/${response.idPgd}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(handle401Error)
                    .then(res => {
                        if (res) {
                            return res.json();
                        } 
                        return undefined;
                    })
                    .then((response) => {
                        setReady(true)
                        showPdf()
                        if (response.isEnable) {
                            setEnable(true)
                        }
                        else {
                            setErrorMessageUserDisable(response.message)
                            setEnable(false)
                        }
                    })
                    .catch(() => { setServerError(true) })
            })
            .catch((err) => {
                refresh = localStorage.getItem('refresh')
                if (refresh === 'false') {
                    window.location.reload();
                    localStorage.setItem('refresh', "true")
                }
                else {
                    callbackOnDelay(() => setServerError(true))
                }
            })
        return () => localStorage.removeItem('refresh')
    }, [])

    const checkMail = (mail: string) => {
        setShowEmailError(false)
        if (mail === "") {
            // setDisableCheckMail(true)
            setIsErrorEmail(true)
            setErrorMessageEmail("Campo obbligatorio")
            setEmailIsValid(false)
        }
        else if (mail.match(EMAIL_REGEX)) {
            setEmailIsValid(true)
            setIsErrorEmail(false)
            // setDisableCheckMail(false)
            fetch(`${apiPathPrivate}/v1/segnalazione/verifica-mail`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    otp: "",
                    mail: requestSegnalazione.email,
                    idPgd: metadata?.idPgd
                })
            })
                // .then(res => res.json())
                .then(handle401Error)
                .then((response) => {
                    setDisableCheckMail(true)
                    setShowMailInfo(true);
                    dispatch(setCode(""));
                })
                .catch(() => {
                    setShowEmailError(true)
                })
        }
        else {
            // setDisableCheckMail(true)
            setIsErrorEmail(true)
            setErrorMessageEmail("Inserire un indirizzo email valido")
            setEmailIsValid(false)
        }
    }

    const checkCode = (otp: string) => {
        fetch(`${apiPathPrivate}/v1/segnalazione/verifica-otp`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                otp: otp,
                mail: requestSegnalazione.email,
                idPgd: metadata?.idPgd
            })
        })
            .then(handle401Error)
            .then(res => {
                if (res) {
                    return res.json();
                } 
                return undefined;
            })
            .then((response) => {
                setDisableCheckCode(true)
                if (response.isEnable) {
                    setCodeIsValid(true)
                    setIsErrorCode(false)
                    setErrorMessageCode("")
                }
                else {
                    setIsErrorCode(true)
                    setErrorMessageCode(response.message)
                }
            })
            .catch(() => {
                // setShowCodeError(true)
            })
    }

    const testoSegnalazioneCallback = (val: string) => {
        const replaceText = val.replaceAll("‘", "'").replaceAll("’", "'").replaceAll("“", '"').replaceAll("”", '"')
        dispatch(setTesto(replaceText))
        setIsErrorTesto(false)
        setShowFailed(false)
        setShowSuccess(false)
    }
    const emailCallback = (val: string) => {
        setIsErrorEmail(false)
        setCodeIsValid(false)
        setIsErrorCode(false)
        setShowMailInfo(false)
        setShowFailed(false)
        setShowSuccess(false)
        setEmailIsValid(false)
        setShowEmailError(false)
        dispatch(setEmail(val))
        dispatch(setCode(""))
        if (disableCheckMail) {
            if (val !== "") {
                setDisableCheckMail(false)
            }
        }
    }

    const codeCallback = (val: string) => {
        dispatch(setCode(val))
        setCodeIsValid(false)
        setShowFailed(false)
        setShowSuccess(false)
        if (val === "") {
            setDisableCheckCode(true)
        }
        else {
            setDisableCheckCode(false)
        }
    }

    const enableSubmit = () => {
        if (checkPrivacy && requestSegnalazione.testo !== "" && requestSegnalazione.email !== "" && requestSegnalazione.code !== "") {
            return true
        }
        return false;
    }

    useEffect(() => {
        if (codeIsValid) {
            if (enableSubmit()) {
                setDisableSubmit(false)
            }
            else {
                setDisableSubmit(true)
            }
        }
        else {
            setDisableSubmit(true)
        }
    }, [codeIsValid, requestSegnalazione, checkPrivacy])

    const showPdf = () => {
        const apiPath = process.env.REACT_APP_SERVER_HOST_PUBLIC
        fetch(`${apiPath}/v1/search/content/${state?.idFilenet}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(handle401Error)
            .then(res => {
                if (res) {
                    return res.json();
                } 
                return undefined;
            })
            .then((response) => {
                setUrlFile("data:application/pdf;base64," + response.content + "#view=FitH");
                setReady(true)
            })
    }

    const submitForm = (
        requestSegnalazione: {
            testo: string,
            email: string
            code: string
        },
        event: FormEvent) => {
        event.preventDefault();
        if (requestSegnalazione) {
            setShowFailed(false)
            if (requestSegnalazione.testo !== "" && requestSegnalazione.email !== "") {
                setIsErrorTesto(false)
                setIsErrorEmail(false)
                if (checkWhiteList(requestSegnalazione.testo)) {
                    setRegexFailed(false)
                    fetch(`${apiPathPrivate}/v1/segnalazione/privacy/${metadata?.idPgd}`, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            checked: checkPrivacy,
                        })
                    })
                        .then(() => {
                            fetch(`${apiPathPrivate}/v1/segnalazione/submit`, {
                                method: 'POST',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    mail: requestSegnalazione.email,
                                    nota: requestSegnalazione.testo,
                                    idPgd: metadata?.idPgd,
                                    idFilenet: state?.idFilenet,
                                    otp: requestSegnalazione.code?.trim()
                                })
                            })
                                // .then(res => res.json())


                                    .then(handle401Error)
                                    .then((responseSubmit) => {


                                        if (responseSubmit && responseSubmit.ok) {

                                        setDisableSubmit(true)
                                        setDisableAllFields(true)
                                        setShowSuccess(true)
                                        setShowFailed(false)
                                    }
                                    else {
                                        // searchDetailsDispatch(clearSegnalazione(""));
                                        dispatch(setEmail(""))
                                        dispatch(setCode(""))
                                        setEmailIsValid(false)
                                        setShowMailInfo(false)
                                        setDisableSubmit(true)
                                        setShowSuccess(false)
                                        setShowFailed(true)
                                        setSubmitFailedMessage(sendTryErrorMessage);
                                        setCodeIsValid(false);

                                    }
                                })
                                .catch(() => {
                                    setDisableSubmit(false)
                                    setShowSuccess(false)
                                    setShowFailed(true)
                                })
                        })
                        .catch(() => {
                            setDisableSubmit(false)
                            setShowSuccess(false)
                            setShowFailed(true)
                        })
                }
                else {
                    setRegexFailed(true)
                }

            }
            else {
                if (requestSegnalazione.testo === "") {
                    setIsErrorTesto(true)
                }
                if (requestSegnalazione.email === "") {
                    setIsErrorEmail(true)
                    setErrorMessageEmail("Campo obbligatorio")
                }
                setTimeout(() => {
                    const error = document.getElementsByClassName('Search')[0]
                    error?.scrollIntoView()
                }, 100);
            }
        }
    }

    const checkWhiteList = (text: string) => {
        const regex = TEXTAREA_WHITELIST_REGEX
        return regex.test(text)
    }

    const clearRequestParams = () => {
        searchDetailsDispatch(clearRequest(""))
        localStorage.removeItem('request')
        localStorage.removeItem('response')
        localStorage.removeItem('paginationDetails')
    }

    return (
        state ?
            <div className="container py-3">
                <Row className="my-3">
                    <Col className="col-md-0">
                        <nav aria-label="Ti trovi in">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to='/' onClick={clearRequestParams}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathHomeLinkLabel}</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to='/ricerca' onClick={clearRequestParams}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathLinkLabel}</Link>
                                </li>
                                <li className="link-color breadcrumb-item" aria-current="page">
                                    <Link to='/ricerca' onClick={() => { searchDetailsDispatch(setReturnFromDetails(true)); searchDetailsDispatch(clearSegnalazione("")) }}>{CONSTANTS.SEARCH_DETAILS_PAGE.searchDetailsPathListLinkLabel}</Link>
                                </li>
                                <li className="link-color breadcrumb-item" aria-current="page">
                                    <Link to={`/ricerca/dettaglio`} state={state} onClick={() => { searchDetailsDispatch(setReturnFromDetails(true)); searchDetailsDispatch(clearSegnalazione("")) }}>{"Dettaglio sentenza"}</Link>
                                </li>
                                <li className="link-color breadcrumb-item active" aria-current="page">{`Segnala sentenza`}</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                {ready ?
                    <div role="region" aria-label="form segnala sentenza">
                        <Row className="my-3">
                            <Col className="col-md-0">
                                <h2 className="link-color">
                                    {sentenceTitle}
                                </h2>
                            </Col>
                        </Row>
                        <SentenceAccordion ready={ready} urlFile={urlFile} infoContent={infoContent} showInfo={stateFlgTedesco === 'true' ? true : false} open={false} />
                        {enable && <Form id='segnala-sentenza'>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col className="col-auto">
                                        <SearchItem
                                            callback={testoSegnalazioneCallback}
                                            value={requestSegnalazione.testo}
                                            maxlength={200}
                                            isError={isErrorTesto || regexFailed}
                                            errorMessage={regexFailed ? 'Attenzione: sono stati inseriti caratteri non ammessi.' : 'Campo obbligatorio'}
                                            id="FormSegnalaSentenza.ControlInput2"
                                            title="Testo della segnalazione (indicare dettagliatamente il testo da correggere e la pagina della sentenza)"
                                            subtitle="Si ricorda che il presente form deve essere utilizzato esclusivamente per segnalazioni relative ad anomalie nel mascheramento della sentenza"
                                            placeholder="Inserisci testo"
                                            type="textArea"
                                            disabled={disableAllFields}
                                            required={true} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-3">
                                        <SearchItem
                                            callback={emailCallback}
                                            initialValue={requestSegnalazione.email}
                                            value=""
                                            isError={isErrorEmail}
                                            isValid={emailIsValid}
                                            errorMessage={errorMessageEmail}
                                            id="FormSegnalaSentenza.ControlInput3"
                                            title="Email"
                                            placeholder="Inserisci email"
                                            type="control"
                                            required={true}
                                            disabled={disableAllFields}
                                        />
                                    </Col>
                                    <Col className="col-3 mt-4">
                                        <Button
                                            disabled={disableCheckMail}
                                            variant="outline-primary"
                                            onClick={() => checkMail(requestSegnalazione.email)}
                                            className='mt-1'
                                        >
                                            {"Verifica email"}
                                        </Button>
                                    </Col>
                                </Row>
                                {showEmailError ? <div id="alert-danger" className="alert alert-danger" role="alert">{emailError}</div> : null}
                                {showMailInfo ? <div id="alert-info" className="alert alert-info" role="alert">Controlla la casella di posta e segui le istruzioni per confermare il tuo indirizzo di posta elettronica.</div> : null}
                                {showMailInfo ?
                                    <Row>
                                        <Col className="col-md-3">
                                            <SearchItem
                                                callback={codeCallback}
                                                initialValue={requestSegnalazione.code}
                                                value=""
                                                isError={isErrorCode}
                                                isValid={codeIsValid}
                                                errorMessage={errorMessageCode}
                                                id="FormSegnalaSentenza.ControlInput4"
                                                title="Inserisci il codice ricevuto per email"
                                                placeholder="Inserisci codice"
                                                type="control"
                                                required={true}
                                                disabled={disableAllFields}
                                                disableAutocomplete={true}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-4">
                                            <Button
                                                disabled={disableCheckCode}
                                                variant="outline-primary"
                                                onClick={() => checkCode(requestSegnalazione.code?.trim())}
                                                className='mt-1'
                                            >
                                                {"Verifica codice"}
                                            </Button>
                                        </Col>
                                    </Row>
                                    : null}
                                <Row>
                                    <Col className="col-auto">
                                        <Form.Label htmlFor="FormSegnalaSentenza.ControlInput5" className="form-label">
                                            Informativa sulla privacy:*
                                        </Form.Label>
                                        <div className="mb-2">
                                            <Button
                                                type="button"
                                                variant="outline-primary"
                                                onClick={() => setShowPrivacyModal(true)}
                                                data-bs-target="#privacy-modal"
                                                data-bs-toggle="modal"
                                            >
                                                {"Visualizza informativa"}
                                            </Button>
                                            {showPrivacyModal ? <i className="bi bi-check-circle-fill ms-2 text-success"></i> : null}
                                            <Modal title={privacyModalTitle} content={privacyModalContent} id="privacy-modal"></Modal>
                                        </div>
                                        <Form.Check
                                            disabled={!showPrivacyModal}
                                            type={'checkbox'}
                                            label={`L'utente dichiara di aver preso visione dell'informativa ai sensi dell'art. 13 del D.Lgs n. 196 del 30 giugno 2003`}
                                            id={`FormSegnalaSentenza.ControlInput5`}
                                            onChange={(e) => setCheckPrivacy(!checkPrivacy)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3 mt-4 mb-3">
                                        <Button
                                            type="submit"
                                            disabled={disableSubmit}
                                            variant="primary"
                                            onClick={(event) => submitForm(requestSegnalazione, event)}
                                        >
                                            {"Invia segnalazione"}
                                        </Button>
                                    </Col>
                                </Row>
                                {showSuccess ? <div id="alert-success" className="alert alert-success" role="alert">Grazie! La segnalazione è stata inviata correttamente.</div> : null}
                                {showFailed ? <div id="alert-danger" className="alert alert-danger" role="alert">{submitFailedMessage}</div> : null}
                                <div className='mt-3'>I campi contrassegnati con * sono campi obbligatori.</div>
                            </Form.Group>
                        </Form>}
                        {!enable && <div id="alert-warning" className="alert alert-warning" role="alert">{sentenceError}</div>}
                    </div>
                    :
                    serverError ?
                        <Error message="si è verificato un errore!" />
                        :
                        <Spinner />
                }
            </div >
            :
            <Error message="la pagina richiesta non esiste!" styleContainer={true}></Error>
    );
}

export default SegnalaSentenzaPage;
